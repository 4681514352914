import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { useEffect, useState } from "react";

import Layout from "./pages/Layout";
import GanttChartPage from "./pages/GanttChartPage";
import {
  AuthService,
  setupAuthInterceptor,
  setupRefreshTokenInterceptor,
} from "./service/api";
import KPIPage from "./pages/KPIPage";
import { useAppDispatch } from "./store/hooks";
import { setInterceptorIsReady } from "./store/apiConfigurationstate";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <GanttChartPage /> },
      { path: "/overview", element: <KPIPage /> },
      { path: "/ganttChart", element: <GanttChartPage /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

const App = () => {
  const [parametersHandled, setParametersHandled] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isNexusLanding = (): boolean =>
    window.location.pathname.toLowerCase() === "/nexus-landing";

  // Tanja
  useEffect(() => {
    if (parametersHandled) return;
    // If this is a Nexus redirect, handle that logic in the separate page component
    if (isNexusLanding()) return;

    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get("token");
    const refreshToken = urlParams.get("refreshToken");

    if (token && refreshToken) {
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", refreshToken);
      setupAuthInterceptor(token);
      setupRefreshTokenInterceptor(refreshToken);
      dispatch(setInterceptorIsReady());
      setParametersHandled(true);
    } else {
      AuthService.redirectToLogin();
    }
  }, [parametersHandled]);

  return <RouterProvider router={router} />;
};
export default App;
